<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <div class="Enter-top">
        <div>
          <div>
            <a-breadcrumb style="margin: 18px 0">
              <a-breadcrumb-item>财务管理</a-breadcrumb-item>
              <a-breadcrumb-item>收支流水</a-breadcrumb-item>
            </a-breadcrumb>
          </div>
        </div>
        <div v-if="Id == ''" class="Enter-top-right">
          <a-input-search placeholder="请输入搜索内容" style="width: 150px" />
        </div>
        <div v-else>
          <a-icon
            @click="
              () => {
                this.Id = ''
                this.visible = false
              }
            "
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>
    <a-layout style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        :style="{
          background: '#fff',
          padding: '24px',
          margin: 0,
          overflow: auto,
        }"
      >
        <!-- 表格 -->
        <div class="search-wrap">
          <a-table
            :row-selection="rowSelection"
            rowKey="id"
            :pagination="false"
            :columns="columns"
            :data-source="flowlist"
            :rowClassName="rowClassName"
          >
            <span slot="type" slot-scope="text">
              {{ text == 0 ? '收入' : '支出' }}
            </span>
            <span slot="time" slot-scope="text">
              {{ text | time }}
            </span>
            <template slot="action" slot-scope="text, record">
              <a @click="onEdit(record)">详情</a>
            </template>
          </a-table>
          <a-pagination
            v-model="params.current"
            :page-size="params.pageSize"
            show-quick-jumper
            :default-current="2"
            :total="params.total"
            @change="onpagesize"
          />
        </div>
        <a-modal
          @cancel="onClose"
          width="750px"
          title="账单信息"
          :visible="visible"
        >
          <a-descriptions layout="vertical" :column="3" title="合同信息">
            <!-- <a-descriptions-item label="出租方">
              1
            </a-descriptions-item> -->
            <a-descriptions-item label="出租方名称">
              {{ detaillist.sign.partyAName }}
            </a-descriptions-item>
            <a-descriptions-item label="出租方联系电话">
              {{ detaillist.sign.partyAContact }}
            </a-descriptions-item>
            <a-descriptions-item label="出租方联系地址">
              {{ detaillist.sign.partyAAddress }}
            </a-descriptions-item>
            <!-- <a-descriptions-item label="承租方">
              1
            </a-descriptions-item> -->
            <a-descriptions-item label="承租方名称">
              {{ detaillist.sign.partyBName }}
            </a-descriptions-item>
            <a-descriptions-item label="承租方联系电话">
              {{ detaillist.sign.partyBContact }}
            </a-descriptions-item>
            <a-descriptions-item label="承租方联系地址">
              {{ detaillist.sign.partyBAddress }}
            </a-descriptions-item>
          </a-descriptions>
          <a-descriptions layout="vertical" :column="3" title="账单信息">
            <a-descriptions-item label="起止日期">
              {{ detaillist.bill.startDate }}-{{ detaillist.bill.endDate }}
            </a-descriptions-item>
            <a-descriptions-item label="已支付金额">
              {{
                detaillist.bill.payed == null ? '0' : detaillist.bill.payed
              }}元
            </a-descriptions-item>
            <a-descriptions-item label="账单金额">
              {{ detaillist.bill.totalFee }}
            </a-descriptions-item>
            <a-descriptions-item label="出账状态">
              {{ detaillist.bill.state == 0 ? '未出账' : '已出账' }}
            </a-descriptions-item>
            <a-descriptions-item label="结清状态">
              {{ detaillist.bill.confirm == 0 ? '未结清' : '已结清' }}
            </a-descriptions-item>
          </a-descriptions>
          <a-descriptions layout="vertical" :column="4" title="流水信息">
            <a-descriptions-item label="付款人">
              {{ detaillist.payByName }}
            </a-descriptions-item>
            <a-descriptions-item label="流水金额">
              {{ detaillist.fee }}元
            </a-descriptions-item>
            <a-descriptions-item label="备注">
              {{ detaillist.note }}
            </a-descriptions-item>
            <a-descriptions-item label="创建时间">
              {{ detaillist.createTime | time }}
            </a-descriptions-item>
          </a-descriptions>
          <template slot="footer">
            <a-button @click="onClose">取消</a-button>
          </template>
        </a-modal>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
const columns = [
  {
    title: '收支类型',
    dataIndex: 'type',
    scopedSlots: { customRender: 'type' },
  },
  {
    title: '付款方',
    dataIndex: 'payByName',
  },
  {
    title: '收款方',
    dataIndex: 'payToName',
  },
  {
    title: '金额',
    dataIndex: 'fee',
  },
  {
    title: '交易单号',
    dataIndex: 'code',
  },
  {
    title: '备注',
    dataIndex: 'note',
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'time' },
  },
  {
    title: '创建人',
    dataIndex: 'creatorName',
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
import http from '../../../http'
export default {
  data() {
    return {
      columns,
      title: '',
      visible: false,
      placement: 'right',
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },

      params: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      flowlist: [],
      detaillist: {
        sign: {
          partyAName: '',
          partyAContact: '',
          partyAAddress: '',
          partyBName: '',
          partyBContact: '',
          partyBAddress: '',
        },
        bill: {
          totalFee: '',
          payed: '',
          confirm: '',
          state: '',
          startDate: '',
          endDate: '',
        },
        payByName: '',
        fee: '',
        note: '',
        createTime: '',
      },
      selectedRowKey: [],
      Id: '',
      form: {
        id: '',
        superiorId: '',
        permissions: '新增',
        path: '',
        name: '',
        type: 0,
        icon: '',
        state: '',
      },
    }
  },
  filters: {
    time(time) {
      if (time == null) {
        return ' '
      }
      var times = time.split('T')
      return times[0] + ' ' + times[1]
    },
  },
  methods: {
    onClose() {
      this.visible = false
      this.form.id = ''
      this.detaillist = {
        sign: {
          partyAName: '',
          partyAContact: '',
          partyAAddress: '',
          partyBName: '',
          partyBContact: '',
          partyBAddress: '',
        },
        bill: {
          totalFee: '',
          payed: '',
          confirm: '',
          state: '',
          startDate: '',
          endDate: '',
        },
        payByName: '',
        fee: '',
        note: '',
        createTime: '',
      }
    },
    // 编辑
    onEdit(item) {
      this.form.id = item.id
      this.visible = true
      this.getfinanceflowId(item.id)
    },
    onpagesize(e) {
      this.params.current = e
      this.getflowAudit()
    },
    showDrawer() {
      this.visible = true
      this.title = '新增'
    },
    async getflowAudit() {
      try {
        const res = await http.getflowAudit(
          this.params.current,
          this.params.pageSize
        )
        const { success, data } = res.data
        if (success) {
          this.flowlist = data.flows.records
          this.params.total = data.flows.total
          //   console.log(data);
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getfinanceflowId(id) {
      try {
        const res = await http.getfinanceflowId(id)
        const { success, data } = res.data
        if (success) {
          this.detaillist = data.flow
          //   console.log(data);
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    timer() {
      return setTimeout(() => {
        this.getflowAudit()
      }, 500)
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
    handleChange(value) {
      console.log(value)
    },
  },
  mounted() {
    this.getflowAudit()
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          //   console.log("selectedRowKey:" + this.selectedRowKey);
          console.log('selectedRows:', selectedRows)
        },
      }
    },
  },
}
</script>
<style lang="less" scoped>
.search-wrap {
  width: 100%;
  /* background: #999; */
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  min-height: 730px;
  height: 100%;
  padding: 10px 0 6px;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.ant-layout {
  height: 100%;
}
.icon-url {
  position: absolute;
  right: -25px;
  font-size: 18px;
  top: 0px;
  cursor: pointer;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
::v-deep .ant-input-affix-wrapper .ant-input-suffix {
  left: 12px;
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 0;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.ant-form-item {
  height: 60px;
  margin-bottom: 15px;
}
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 500;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
</style>
